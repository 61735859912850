app.controller('addOrcController', ['$scope', '$http', '$window', 'appConfig', '$location', '$localStorage', function ($scope, $http, $window, appConfig, $location, $localStorage) {

    var $ = jQuery;
    $scope.prod_cor = '';

    $scope.getCor = function (cor, prod_cor_id) {
        $scope.prod_cor = cor;
        $scope.prod_cor_id = prod_cor_id;
    };

    $scope.addCart = function () {

        var prod_estoque_id = $('input:hidden[name=prod_estoque_id]').val();
        var prod_id = $('input:hidden[name=prod_id]').val();
        var prod_nome = $('input:hidden[name=prod_nome]').val();
        var prod_valor = $('input:hidden[name=prod_valor]').val();
        var prod_estoque_peso = $('input:hidden[name=prod_estoque_peso]').val();
        var prod_estoque_volume = $('input:hidden[name=prod_estoque_volume]').val();
        var qde = $('input:hidden[name=qde]').val();
        var acao = $('input:hidden[name=acao]').val();
        var foto = $('input:hidden[name=prod_ft_foto]').val();
        var prod_cor = $scope.prod_cor;
        var prod_cor_id = $scope.prod_cor_id;
        var tamanho = ($('#tamanho option:selected').val() != undefined) ? $('#tamanho option:selected').val() : ' - ';

        if (prod_cor === '' || prod_cor === undefined) {
            $.prompt("Por favor, selecione uma cor para adicionar ao orçamento", {
                title: "Atenção"
            });
        } else if (tamanho === '0') {
            $.prompt("Por favor, selecione um tamanho", {
                title: "Atenção"
            });
        } else {
            $.post('/orcamento/cart/addCart',
                {
                    prod_estoque_id: prod_estoque_id,
                    prod_id: prod_id,
                    prod_nome: prod_nome,
                    prod_valor: prod_valor,
                    prod_estoque_peso: prod_estoque_peso,
                    prod_estoque_volume: prod_estoque_volume,
                    foto: foto,
                    qde: qde,
                    prod_cor: prod_cor,
                    prod_cor_id: prod_cor_id,
                    tamanho: tamanho,
                    acao: acao
                },
                function (data) {
                    switch (data.success) {
                        case true:
                            $.prompt(data.msg, {
                                title: data.mod,
                                buttons: {"Ir para o Orçamento": true, "Adicionar mais produtos": false},
                                submit: function (e, v, m, f) {
                                    if (v) {
                                        location.href = data.urlCart;
                                    }
                                    else {

                                    }
                                }
                            });

                            break;
                        case false:

                            break;
                    }
                },
                'json');
        }
        ;
    }


    var init = function () {
        $localStorage.set('url-cont-comprando', $location.absUrl());
    };

    init();

}]);