app.controller('cadUserController', ['$scope', '$http', '$window', 'appConfig', '$localStorage', function ($scope, $http, $window, appConfig, $localStorage) {

    var $ = jQuery;
    $scope.chkBoxMesmoEnd = false;
    $scope.usu_tipo = 'fisica';

    //VERIFICA SE O EMAIL JA EXISTE ///////////////////
    $scope.checkEmailExist = function () {

        var mail = $("input[name='email']");

        mail.removeClass('invalid');
        mail.addClass('load');

        if (mail.val() === '') {

            $.toast({
                heading: 'Atenção',
                text: 'O E-Mail &eacute; Obrigat&oacute;rio.',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: appConfig.timeToast,
                stack: false,
                afterHidden: function () {
                    mail.removeClass('load');
                    mail.focus();
                }
            });

        } else {
            $.post('/user/checkExistMail', {
                email: mail.val()
            }, function (data) {
                if (data == 'true') {

                    mail.removeClass('load');
                    mail.addClass('invalid');

                    $.toast({
                        heading: 'Atenção',
                        text: 'J&aacute; existe um cadastro com esse e-mail!',
                        position: 'mid-center',
                        icon: 'warning',
                        hideAfter: appConfig.timeToast,
                        stack: false
                    });

                } else {

                    mail.removeClass('load');
                    mail.addClass('valid');
                }
            });
        }

    };

    //VERIFICA SE O CPF JA EXISTE ///////////////////
    $scope.checkCPFExist = function () {

        var usu_cpf = $("input[name='usu_cpf']");

        usu_cpf.removeClass('valid');
        usu_cpf.removeClass('invalid');
        usu_cpf.addClass('load');

        if (usu_cpf.val() === '') {

            usu_cpf.removeClass('load');
            usu_cpf.addClass('invalid');

            $.toast({
                heading: 'Atenção',
                text: 'O CPF &eacute; Obrigat&oacute;rio.',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: appConfig.timeToast,
                stack: false,
                afterHidden: function () {
                    usu_cpf.val('');
                    usu_cpf.focus();
                }
            });

        } else {

            if (usu_cpf.hasClass('numeric')) {
                var nan = new RegExp(/(^-?\d\d*\.\d*$)|(^-?\d\d*$)|(^-?\.\d\d*$)/);
                if (!nan.test($.trim(usu_cpf.val()))) {

                    usu_cpf.removeClass('load');
                    usu_cpf.addClass('invalid');

                    $.toast({
                        heading: 'Atenção',
                        text: validateNumericMsg,
                        position: 'mid-center',
                        icon: 'warning',
                        hideAfter: appConfig.timeToast,
                        stack: false,
                        afterHidden: function () {
                            usu_cpf.val('');
                            usu_cpf.focus();
                        }
                    });

                } else {
                    usu_cpf.removeClass('invalid');
                    $.post('/user/checkExistCPF', {
                        usu_cpf: usu_cpf.val()
                    }, function (data) {

                        if (data.success) {

                            usu_cpf.removeClass('load');
                            usu_cpf.addClass('invalid');

                            $.toast({
                                heading: 'Atenção',
                                text: 'Esse CPF j&aacute; se encontra cadastrado!',
                                position: 'mid-center',
                                icon: 'warning',
                                hideAfter: appConfig.timeToast,
                                stack: false,
                                afterHidden: function () {
                                    usu_cpf.val('');
                                    usu_cpf.focus();
                                }
                            });

                        } else {

                            usu_cpf.removeClass('load');
                            usu_cpf.addClass('valid');
                        }
                    });
                }
            }
        }
    };


    //VERIFICA SE O CNPJ JA EXISTE ///////////////////
    $scope.checkCNPJExist = function () {

        var usu_cnpj = $("input[name='usu_cnpj']");

        usu_cnpj.removeClass('valid');
        usu_cnpj.removeClass('invalid');
        usu_cnpj.addClass('load');


        if (usu_cnpj.val() === '') {

            usu_cnpj.removeClass('load');
            usu_cnpj.addClass('invalid');

            $.toast({
                heading: 'Atenção',
                text: 'O CNPJ &eacute; Obrigat&oacute;rio.',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: appConfig.timeToast,
                stack: false,
                afterHidden: function () {
                    usu_cnpj.val('');
                    usu_cnpj.focus();
                }
            });

        } else {

            if (usu_cnpj.hasClass('numeric')) {
                var nan = new RegExp(/(^-?\d\d*\.\d*$)|(^-?\d\d*$)|(^-?\.\d\d*$)/);
                if (!nan.test($.trim(usu_cnpj.val()))) {
                    usu_cnpj.removeClass('load');
                    usu_cnpj.addClass('invalid');

                    $.toast({
                        heading: 'Atenção',
                        text: validateNumericMsg,
                        position: 'mid-center',
                        icon: 'warning',
                        hideAfter: appConfig.timeToast,
                        stack: false,
                        afterHidden: function () {
                            usu_cnpj.val('');
                            usu_cnpj.focus();
                        }
                    });

                } else {
                    usu_cnpj.removeClass('invalid');
                    $.post('/user/checkExistCNPJ', {
                        usu_cnpj: usu_cnpj.val()
                    }, function (data) {

                        if (data.success) {

                            usu_cnpj.removeClass('load');
                            usu_cnpj.addClass('invalid');

                            $.toast({
                                heading: 'Atenção',
                                text: 'Esse CNPJ j&aacute; se encontra cadastrado!',
                                position: 'mid-center',
                                icon: 'warning',
                                hideAfter: appConfig.timeToast,
                                stack: false,
                                afterHidden: function () {
                                    usu_cnpj.removeClass('invalid');
                                    usu_cnpj.val('');
                                    usu_cnpj.focus();
                                }
                            });
                        } else {

                            usu_cnpj.removeClass('load');
                            usu_cnpj.addClass('valid');

                        }
                    });
                }
            }
        }
    };

    //VERIFICA SE SENHA TEM 8 OU + DIGITOS ///////////////////
    $scope.checkSenha = function () {
        var senha = $("input[name='password']").val();

        if (senha === '') {

            $.toast({
                heading: 'Atenção',
                text: 'O campo Senha &eacute; requerido',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: appConfig.timeToast,
                stack: false,
                afterHidden: function () {
                    $("input[name='password']").focus();
                }
            });

        }
        else {
            if (senha.length <= 7) {

                $.toast({
                    heading: 'Atenção',
                    text: 'A senha precisa ter mais de 8 caracteres!',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false,
                    afterHidden: function () {
                        $("input[name='password']").focus();
                    }
                });

            } else {
                $("input[name='password']").addClass('valid');
                $("input[name='password_']").focus();
            }
        }
    };

    //VERIFICA SE A SENHA É IGUAL A RESENHA ///////////////////
    $scope.checkRepeteSenha = function () {

        var senha = $("input[name='password']").val();
        var resenha = $("input[name='password_']").val();

        if (senha !== '' || senha.length >= 8) {
            if (senha == resenha) {
                $("input[name='password']").addClass('valid');
                $("input[name='password_']").addClass('valid');
            } else {

                $.toast({
                    heading: 'Atenção',
                    text: 'As senhas n&atilde;o conferem!',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false,
                    afterHidden: function () {
                        $("input[name='password_']").focus();
                    }
                });
            }
        }
        else {
            $("input[name='password']").focus();
        }
    };

    /* COLOCA A SIGLA DOS ESTADO EM MAIÚSCULA----------------------------------------------------------------------------*/

    $('#usu_ec_uf').keyup(function () {
        this.value = this.value.toUpperCase();
    });
    $('#usu_ee_uf').keyup(function () {
        this.value = this.value.toUpperCase();
    });

    //Copia o Endereço de Cobrança para o de Entrega----------------------------------/
    $scope.mesmoEndereco = function () {
        if ($scope.chkBoxMesmoEnd) {
            $("#usu_ee_tipo").val($("#usu_ec_tipo").val());
            $("#usu_ee_contato").val($("#usu_ec_contato").val());
            $("#usu_ee_endereco").val($("#usu_ec_endereco").val());
            $("#usu_ee_numero").val($("#usu_ec_numero").val());
            $("#usu_ee_complemento").val($("#usu_ec_complemento").val());
            $("#usu_ee_bairro").val($("#usu_ec_bairro").val());
            $("#usu_ee_cep").val($("#usu_ec_cep").val());
            $("#usu_ee_cidade").val($("#usu_ec_cidade").val());
            $("#usu_ee_uf").val($("#usu_ec_uf").val());
        } else {
            $("#usu_ee_tipo").val('');
            $("#usu_ee_contato").val('');
            $("#usu_ee_endereco").val('');
            $("#usu_ee_numero").val('');
            $("#usu_ee_complemento").val('');
            $("#usu_ee_bairro").val('');
            $("#usu_ee_cep").val('');
            $("#usu_ee_cidade").val('');
            $("#usu_ee_uf").val('');
        }
    };

    $scope.checkTelefone = function () {
        var usu_fone1 = $("input[name='usu_fone1']");

        if (usu_fone1.hasClass('numeric')) {
            var nan = new RegExp(/(^-?\d\d*\.\d*$)|(^-?\d\d*$)|(^-?\.\d\d*$)/);
            if (!nan.test($.trim(usu_fone1.val()))) {
                usu_fone1.addClass('invalid');
                usu_fone1.focus();

                $.toast({
                    heading: 'Atenção',
                    text: validateNumericMsg,
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false,
                    afterHidden: function () {
                        usu_fone1.focus();
                    }
                });

                usu_fone1.val('');
            }
            else {
                usu_fone1.removeClass('invalid');
                usu_fone1.addClass('valid');
            }
        }
    };

    $scope.checkCelular = function () {
        var usu_celular = $("input[name='usu_celular']");

        if (usu_celular.hasClass('numeric')) {
            var nan = new RegExp(/(^-?\d\d*\.\d*$)|(^-?\d\d*$)|(^-?\.\d\d*$)/);
            if (!nan.test($.trim(usu_celular.val()))) {
                usu_celular.addClass('invalid');
                usu_celular.focus();

                $.toast({
                    heading: 'Atenção',
                    text: validateNumericMsg,
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false,
                    afterHidden: function () {
                        usu_celular.focus();
                    }
                });

                usu_celular.val('');
            }
            else {
                usu_celular.removeClass('invalid');
                usu_celular.addClass('valid');
            }
        }
    };

    $scope.checkFormUser = function () {


        validate('form-user-new-account');

        if (validateState) {
            var usu_tipo = $("input[name='usu_tipo']:checked").val();
            var usu_cpf = $("#usu_cpf").val();
            var usu_nome = $("#usu_nome").val();
            var usu_cnpj = $("#usu_cnpj").val();
            var usu_razaosocial = $("#usu_razaosocial").val();

            switch (usu_tipo) {
                case 'fisica':
                    if (usu_cpf === '' || usu_nome === '') {

                        $.toast({
                            heading: 'Atenção',
                            text: 'o campo CPF e NOME s&atilde;o obrigat&oacute;rios!',
                            position: 'mid-center',
                            icon: 'warning',
                            hideAfter: appConfig.timeToast,
                            stack: false
                        });
                    }
                    else {
                        addUser();
                    }
                    break;
                case 'juridica':
                    if (usu_cnpj === '' || usu_razaosocial === '') {

                        $.toast({
                            heading: 'Atenção',
                            text: 'o campo CNPJ e RAZÃO SOCIAL s&atilde;o obrigat&oacute;rios !',
                            position: 'mid-center',
                            icon: 'warning',
                            hideAfter: appConfig.timeToast,
                            stack: false
                        });

                    }
                    else {
                        addUser();
                    }
                    break;
            }

        }
    };

    var addUser = function () {

        $.toast({
            heading: 'Informação',
            text: 'Enviando os dados... Aguarde!',
            position: 'mid-center',
            icon: 'info',
            hideAfter: appConfig.timeToast,
            stack: false
        });

        var usu_cpf = $("#usu_cpf").val();
        var usu_cnpj = $("#usu_cnpj").val();
        var usu_tipo = $("input[name='usu_tipo']:checked").val();

        var username = $("#email").val();
        var usu_rg = $("#usu_rg").val();
        var usu_ie = $("#usu_ie").val();
        var password = $("#password").val();
        var usu_nome = $("#usu_nome").val();
        var usu_razaosocial = $("#usu_razaosocial").val();
        var usu_prefixo = $("#usu_prefixo").val();
        var usu_fone1 = $("#usu_fone1").val();
        var usu_celular = $("#usu_celular").val();
        var email = username;

        var usu_ec_tipo = $("#usu_ec_tipo").val();
        var usu_ec_finalidade = "EC";
        var usu_ec_contato = $("#usu_ec_contato").val();
        var usu_ec_endereco = $("#usu_ec_endereco").val();
        var usu_ec_numero = $("#usu_ec_numero").val();
        var usu_ec_complemento = $("#usu_ec_complemento").val();
        var usu_ec_bairro = $("#usu_ec_bairro").val();
        var usu_ec_cep = $("#usu_ec_cep").val();
        var usu_ec_cidade = $("#usu_ec_cidade").val();
        var usu_ec_uf = $("#usu_ec_uf").val();

        var usu_ee_tipo = $("#usu_ee_tipo").val();
        var usu_ee_finalidade = "EE";
        var usu_ee_contato = $("#usu_ee_contato").val();
        var usu_ee_endereco = $("#usu_ee_endereco").val();
        var usu_ee_numero = $("#usu_ee_numero").val();
        var usu_ee_complemento = $("#usu_ee_complemento").val();
        var usu_ee_bairro = $("#usu_ee_bairro").val();
        var usu_ee_cep = $("#usu_ee_cep").val();
        var usu_ee_cidade = $("#usu_ee_cidade").val();
        var usu_ee_uf = $("#usu_ee_uf").val();


        $.post('/user/createAccount', {

            usu_tipo: usu_tipo,
            username: username,
            password: password,
            usu_nome: usu_nome,
            usu_razaosocial: usu_razaosocial,
            usu_cpf: usu_cpf,
            usu_rg: usu_rg,
            usu_cnpj: usu_cnpj,
            usu_ie: usu_ie,
            usu_prefixo: usu_prefixo,
            usu_fone1: usu_fone1,
            usu_celular: usu_celular,
            email: email,

            usu_ec_tipo: usu_ec_tipo,
            usu_ec_finalidade: usu_ec_finalidade,
            usu_ec_contato: usu_ec_contato,
            usu_ec_endereco: usu_ec_endereco,
            usu_ec_numero: usu_ec_numero,
            usu_ec_complemento: usu_ec_complemento,
            usu_ec_bairro: usu_ec_bairro,
            usu_ec_cep: usu_ec_cep,
            usu_ec_cidade: usu_ec_cidade,
            usu_ec_uf: usu_ec_uf,

            usu_ee_tipo: usu_ee_tipo,
            usu_ee_finalidade: usu_ee_finalidade,
            usu_ee_contato: usu_ee_contato,
            usu_ee_endereco: usu_ee_endereco,
            usu_ee_numero: usu_ee_numero,
            usu_ee_complemento: usu_ee_complemento,
            usu_ee_bairro: usu_ee_bairro,
            usu_ee_cep: usu_ee_cep,
            usu_ee_cidade: usu_ee_cidade,
            usu_ee_uf: usu_ee_uf

        }, function (data) {

            switch (data.success) {
                case false:

                    $.toast({
                        heading: 'Erro',
                        text: data.msg,
                        position: 'mid-center',
                        icon: 'error',
                        hideAfter: appConfig.timeToast,
                        stack: false
                    });

                    break;

                case true:

                    $.toast({
                        heading: 'Sucesso',
                        text: data.msg,
                        position: 'mid-center',
                        icon: 'success',
                        hideAfter: appConfig.timeToast,
                        stack: false,
                        afterHidden: function () {
                            //clearFormUser();
                            //TODO: implementar o storageLocation para pegar a URL de onde o usuário veio.
                            if($localStorage.get('checkout')){
                                location.href = '/auth/login';
                            }else{
                                location.href = '/';
                            }
                        }
                    });

                    break;
                default:
                    $.toast({
                        heading: 'Erro',
                        text: 'Erro não definido, entre em contato com o administrador',
                        position: 'mid-center',
                        icon: 'error',
                        hideAfter: appConfig.timeToast,
                        stack: false
                    });
                    break;
            }

        }, 'json');
    };

    var clearFormUser = function () {
        $scope.usu_tipo = 'fisica';

        $('.form-user-new-account')[0].reset();

        $("#end_igual").attr("checked", false);

        $("input[name='usu_cpf']").removeClass('valid');
        $("input[name='password']").removeClass('valid');
        $("input[name='email']").removeClass('valid');
    };

    var init = function () {
        clearFormUser();
    };


    init();

}]);