app.controller('contatoController', ['$scope', '$http', 'appConfig', function contatoController($scope, $http, appConfig) {

    var $ = jQuery;
    var base_url = window.location.origin;

    $scope.Enviar = function () {

        validate('form-contato');

        if (validateState) {

            $.toast({
                heading: 'Informação',
                text: 'Enviando Mensagem. Aguarde...',
                position: 'mid-center',
                icon: 'info',
                hideAfter: appConfig.timeToast,
                stack: false
            });

            var inputSetor = $("#inputSetor").val();
            var inputNome = $("#inputNome").val();
            var inputEmail = $("#inputEmail").val();
            var inputFone = $("#inputFone").val();
            var cli_uf = $('#cli_uf option:selected').text();//$("#cli_uf").attr("name");
            var cli_cidade = $("#cli_cidade").val();
            var inputAssunto = $("#inputAssunto").val();
            var inputMSG = $("#inputMSG").val();
            var recaptchatoken = $("#g-recaptcha-response").val();

            if (recaptchatoken === "") {
                $.toast.reset();
                $.toast({
                    heading: 'Atenção',
                    text: 'Você não clicou no reCAPTCHA, por favor, faça!',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });
            } else {
                $.post(base_url + '/contato/sendmail',
                    {
                        inputSetor: inputSetor,
                        inputNome: inputNome,
                        inputEmail: inputEmail,
                        inputFone: inputFone,
                        cli_uf: cli_uf,
                        cli_cidade: cli_cidade,
                        inputAssunto: inputAssunto,
                        inputMSG: inputMSG,
                        recaptchatoken: recaptchatoken,
                    },
                    function (data) {
                        switch (data.success) {

                            case 'true':
                                $.toast().reset();
                                $.toast({
                                    heading: 'Sucesso',
                                    text: data.erroMsg,
                                    position: 'mid-center',
                                    icon: 'success',
                                    hideAfter: appConfig.timeToast,
                                    stack: false
                                });
                                reset();
                                break;
                            case 'false':
                                $.toast.reset();
                                $.toast({
                                    heading: 'Erro',
                                    text: data.erroMsg,
                                    position: 'mid-center',
                                    icon: 'error',
                                    hideAfter: appConfig.timeToast,
                                    stack: false
                                });

                            case 'false_captcha':
                                $.toast.reset();
                                $.toast({
                                    heading: 'Erro',
                                    text: data.erroMsg,
                                    position: 'mid-center',
                                    icon: 'error',
                                    hideAfter: appConfig.timeToast,
                                    stack: false
                                });

                                break;
                        }

                    }, 'json');
            }
        }//end if validateState
    }

    var reset = function () {
        $("#inputSetor").val('');
        $("#inputNome").val('');
        $("#inputEmail").val('');
        $("#inputPrefixo").val('');
        $("#inputFone").val('');
        $("#cli_cidade").val('');
        $("#cli_uf").val('');
        $("#inputAssunto").val('');
        $("#inputMSG").val('');
        $("#code").val('');
    }

    $scope.estados = [
        {name: 'Acre', value: '1'},
        {name: 'Alagoas', value: '2'},
        {name: 'Amazonas', value: '3'},
        {name: 'Amapá', value: '4'},
        {name: 'Bahia', value: '5'},
        {name: 'Ceará', value: '6'},
        {name: 'Distrito Federal', value: '7'},
        {name: 'Espírito Santo', value: '8'},
        {name: 'Goiás', value: '9'},
        {name: 'Maranhão', value: '10'},
        {name: 'Minas Gerais', value: '11'},
        {name: 'Mato Grosso do Sul', value: '12'},
        {name: 'Mato Grosso', value: '13'},
        {name: 'Pará', value: '14'},
        {name: 'Paraíba', value: '15'},
        {name: 'Pernambuco', value: '16'},
        {name: 'Piauí', value: '17'},
        {name: 'Paraná', value: '18'},
        {name: 'Rio de Janeiro', value: '19'},
        {name: 'Rio Grande do Norte', value: '20'},
        {name: 'Rondonia', value: '21'},
        {name: 'Roraima', value: '22'},
        {name: 'Rio Grande do Sul', value: '23'},
        {name: 'Santa Catarina', value: '24'},
        {name: 'Sergipe', value: '25'},
        {name: 'São Paulo', value: '26'},
        {name: 'Tocantins', value: '27'}
    ];

    $scope.estado = $scope.estados[12];

    var init = function () {
        reset();
        $scope.cidades();
    };

    /* PEGAR AS CIDADES -----------------------------------------------------------------------------------*/


    $scope.cidades = function () {

        $('.carregando').show();
        $http.get('estado/' + $scope.estado.value).then(function (data) {
            $scope.listacontatocidades = data.data;
            if ($scope.estado.value == 13) {
                $scope.cidade = $scope.listacontatocidades[74];
            }
            else {
                $scope.cidade = $scope.listacontatocidades[0];
            }
            $('.carregando').hide();
        });
    };

    init();

}]);