app.controller('loginPopUpController', ['$scope', '$http', '$window', 'appConfig', '$location', function ($scope, $http, $window, appConfig, $location) {

    var $ = jQuery;
    var myToast;

    $scope.logout = function () {
        $.get('/auth/logout',
            { },
            function (data) {
            if(data.success){
                location.href = '/';
            }
            }, 'json');
    };

    $scope.logar = function () {
        $scope.resposta = 'Login efetuado com sucesso!!';
        var statesdemo = {
            state0: {
                html: '<div class="container-jqi-400"><div class="row"><div class="col-12">' +
                '<div class="form-group"><label class="control-label">E-Mail</label>' +
                '<input type="email" class="form-control" name="email"></div><div class="form-group">' +
                '<label class=" control-label">Senha</label><input type="password" class="form-control" name="password"></div><div class="form-group">' +
                '<div class="checkbox"><label></label></div></div><div class="clearfix"><div class="form-group col-xs-12">' +
                '<p><a href="/password/email"></a></p></div></div></div></div></div>',
                buttons: {Cancelar: false, Logar: true},
                focus: 1,
                submit: function (e, v, m, f) {
                    var e = "";
                    if (v) {
                        if (f.email === "") {
                            e += "Por favor, digite o e-mail <br>";
                        }
                        if (f.password === "") {
                            e += "Por favor, digite a senha <br>";
                        }
                        if (e === "") {
                            var email = $('input[name=email]').val();
                            var password = $('input[name=password]').val();
                            var rota = $location.absUrl();
                            $.post('/auth/logar',
                                {
                                    email: email,
                                    password: password,
                                    rota: rota
                                },
                                function (data) {
                                    if (data.success == true) {
                                        $.prompt.goToState('state1');
                                        $('.jqiform')[0].reset();
                                        return false;
                                    }
                                    else {
                                        $.prompt.goToState('state2');
                                        return false;
                                    }
                                }, 'json');

                        } else {
                            $('<div class="errorBlock" style="display: none;">' + e + '</div>').prependTo(m).show('slow');
                        }
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            },
            state1: {
                html: '<p class="mensagem-success">Login efetuado com sucesso!</p>',
                buttons: {Fechar: 0},
                focus: 1,
                submit: function (e, v, m, f) {
                    e.preventDefault();
                    if (v === 0) {
                        $.prompt.close();
                    }
                    else if (v == -1) {
                        $.prompt.goToState('state0');
                    }
                }
            },
            state2: {
                html: '<p class="mensagem-erro">Não foi possivel fazer o login, tente novamente!</p>',
                buttons: {Voltar: -1, Fechar: 0},
                focus: 1,
                submit: function (e, v, m, f) {
                    e.preventDefault();
                    if (v === 0) {
                        $.prompt.close();
                    }
                    else if (v == -1) {
                        $.prompt.goToState('state0');
                    }
                }
            }
        };

        $.prompt(statesdemo);
    };

}]);