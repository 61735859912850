app.controller('orcamentoController', function orcamentoController($scope, $http, $window) {

    var $ = jQuery;
    var base_url = $window.location.origin;
    $scope.produtos = new Array();
    $scope.obj = new Object();
    $scope.empresa = new Object();

    $scope.Enviar = function () {

        validate('form-orcamento');

        if (validateState) {

            var myToastA = $().toastmessage('showToast', {
                text: 'Enviando Orçamento... Aguarde!',
                position: 'middle-center',
                type: 'notice',
                sticky: true,
                closeText: ''
            });

            $("input[type=number][name='qde[]']").each(function () {
                if ($(this).val() > 0) {
                    $scope.produtos.push($scope.criaObjeto($(this).val(), $(this).attr('id')));
                    $scope.obj = {};
                }

            });

            $.post(base_url + '/contato/orcamento',
                {
                    empresa: $scope.empresa,
                    produtos: $scope.produtos
                },
                function (data) {
                    switch (data.success) {

                        case 'true':

                            $().toastmessage('removeToast', myToastA);

                            var myToast = $().toastmessage('showToast', {
                                text: data.erroMsg,
                                position: 'middle-center',
                                type: 'success',
                                sticky: false,
                                close: function () {
                                    $('#orcamento-itens')[0].reset();
                                },
                                closeText: ''
                            });
                            break;
                        case 'false':
                            $().toastmessage('removeToast', myToastA);

                            var myToast = $().toastmessage('showToast', {
                                text: data.erroMsg,
                                position: 'middle-center',
                                type: 'error',
                                sticky: false,
                                close: function () {

                                },
                                closeText: ''
                            });
                            break;
                    }

                }, 'json');

        }
    }

    $scope.criaObjeto = function (qde, nome) {
        $scope.obj.qde = qde;
        $scope.obj.nome = nome;

        return $scope.obj;
    };


})
;