app.controller('checkoutController', ['$scope', '$http', '$window', 'appConfig', '$localStorage', function ($scope, $http, $window, appConfig, $localStorage) {

    var $ = jQuery;
    $scope.data = {active: false};
    $scope.active = true;
    $scope.result = {
        total_parcial: 0.00,
        total_parcial_usd: 0.00,
        frete: '0,00',
        total_pedido: 0.00,
        total_pedido_usd: 0.00
    };

    $scope.toggle = function () {
        $scope.data.active = !$scope.data.active;
    };

    $scope.pagarDeposito = function () {
        $.prompt("Você escolheu <strong>DEPÓSITO</strong> como opção de pagamento, você confirma?", {
            title: "Atenção",
            buttons: {"Sim, eu confirmo": true, "Escolher Outra Forma": false},
            submit: function (e, v, m, f) {
                if (v) {
                    var v1 = '';
                    var v2 = '';

                    $.post('/commerce/checkout/depositoBB/',
                        {v1: v1, v2: v2},
                        function (data) {
                            if (data) {
                                location.href = '/user/dashboard/forma/deposito';
                            }
                        });
                }
            }
        });

    };

    $scope.pagarBoleto = function (banco) {
        $.prompt("Você escolheu <strong>BOLETO BANCÁRIO</strong> como opção de pagamento, você confirma?", {
            title: "Atenção",
            buttons: {"Sim, eu confirmo": true, "Escolher Outra Forma": false},
            submit: function (e, v, m, f) {
                if (v) {
                    var v1 = '';
                    var v2 = '';

                    $.post('/commerce/checkout/pagtoBoleto/banco/' + banco,
                        {v1: v1, v2: v2},
                        function (data) {
                            if (data) {
                                location.href = '/user/dashboard/forma/boleto/banco/' + banco;
                            }
                        });
                }
            }
        });

    };

    $scope.pagarCartao = function (cartao) {

        var parc = $('#parcelas_' + cartao).val();

        if ((parc == 0) || (parc == undefined)) {
            $.prompt("Selecione a quantidade de parcelas", {title: "Atenção"});
        }
        else {
            $.prompt("Você escolheu CARTÃO " + cartao.toUpperCase() + " em " + $('#parcelas_' + cartao).val() + "x sem juros, você confirma?", {
                title: "Atenção",
                buttons: {"Sim, eu confirmo": true, "Escolher Outra Forma": false},
                submit: function (e, v, m, f) {
                    if (v) {
                        location.href = '/commerce/checkout/pagtoGatewayCieloDirect/';
                    }
                }
            });
        }
    };

    $scope.onChangeSelectParcelas = function (cartao) {

        var card = cartao;
        var parc = $("#parcelas_" + cartao).val();
        var total = divideParcelas($scope.result.total_pedido_usd, parc);

        if (parc != 0) {
            switch (cartao) {
                case 'visa':
                    $scope.parcela_mastercard = $scope.parcelas_mastercard[0];
                    $scope.parcela_elo = $scope.parcelas_elo[0];
                    $scope.parcela_amex = $scope.parcelas_amex[0];
                    $('#parc_mastercard').html('&nbsp;');
                    $('#parc_elo').html('&nbsp;');
                    $('#parc_amex').html('&nbsp;');
                    break;
                case 'mastercard':
                    $scope.parcela_visa = $scope.parcelas_visa[0];
                    $scope.parcela_elo = $scope.parcelas_elo[0];
                    $scope.parcela_amex = $scope.parcelas_amex[0];
                    $('#parc_visa').html('&nbsp;');
                    $('#parc_elo').html('&nbsp;');
                    $('#parc_amex').html('&nbsp;');
                    break;
                case 'amex':
                    $scope.parcela_visa = $scope.parcelas_visa[0];
                    $scope.parcela_elo = $scope.parcelas_elo[0];
                    $scope.parcela_mastercard = $scope.parcelas_mastercard[0];
                    $('#parc_visa').html('&nbsp;');
                    $('#parc_elo').html('&nbsp;');
                    $('#parc_mastercard').html('&nbsp;');
                    break;
                case 'elo':
                    $scope.parcela_visa = $scope.parcelas_visa[0];
                    $scope.parcela_mastercard = $scope.parcelas_mastercard[0];
                    $scope.parcela_amex = $scope.parcelas_amex[0];
                    $('#parc_visa').html('&nbsp;');
                    $('#parc_mastercard').html('&nbsp;');
                    $('#parc_amex').html('&nbsp;');
                    break;
                default:
                    break;
            }

            $('#parc_' + cartao).html('R$ ' + numberFormat(parseFloat(total).toFixed(2)));

            $.post('/commerce/checkout/setBandeiraParcela/',
                {bandeira: card, parcela: parc},
                function (data) {
                    if (data) {
                        //
                    }
                }, 'json');
        } else {
            $('#parc_elo').html('&nbsp;');
            $('#parc_visa').html('&nbsp;');
            $('#parc_mastercard').html('&nbsp;');
            $('#parc_amex').html('&nbsp;');
        }

    };

    var init = function () {
        $http.get('/commerce/cart/getCart').then(function (result) {
            if (result.data[0].success == false) {
                $.toast({
                    heading: 'Informação',
                    text: 'Checkout está vazio',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 3000,
                    beforeHide: function () {
                        location.href = "/commerce";
                    },
                    stack: false
                });
            } else {
                $scope.itens = result.data;
                $localStorage.set('lista_url_back', result.data[0].url_lista);

                $scope.result.frete = numberFormat(parseFloat(result.data[0].frete).toFixed(2));
                $scope.result.total_parcial = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));
                $scope.result.total_parcial_usd = parseFloat(result.data[0].total_parcial).toFixed(2);
                $scope.result.total_pedido = numberFormat(parseFloat(result.data[0].total_pedido).toFixed(2));
                $scope.result.total_pedido_usd = parseFloat(result.data[0].total_pedido).toFixed(2);

            }
        });

        $scope.getEnderecoPrincipal();

    };

    var numberFormat = function (n) {
        var parts = n.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
    };

    var divideParcelas = function (total, parcela) {
        var tot = total / parcela;

        return tot;
    };

    var getParcelasCartoes = function () {
        $http.get('/commerce/checkout/getParcelasCartoes/').then(function (result) {

            $scope.parcelas_visa = result.data.visa;
            $scope.parcela_visa = $scope.parcelas_visa[0];

            $scope.parcelas_mastercard = result.data.mastercard;
            $scope.parcela_mastercard = $scope.parcelas_mastercard[0];

            $scope.parcelas_amex = result.data.amex;
            $scope.parcela_amex = $scope.parcelas_amex[0];

            $scope.parcelas_elo = result.data.elo;
            $scope.parcela_elo = $scope.parcelas_elo[0];

        });

    };

    $('#usu_end_uf').keyup(function () {
        this.value = this.value.toUpperCase();
    });


    $scope.addNovoEndereco = function () {
        $scope.resposta = 'Endereço cadastrado com sucesso!!';
        var statesdemo = {
            state0: {
                html: '<fieldset class="container-jqi-850">' +
                '<div class="form-group row"><label class="col-sm-2 control-label"></label><div class="col-md-5"><label class="checkbox-inline" for="usu_end_principal">' +
                '<input type="checkbox" name="usu_end_principal" id="usu_end_principal"><strong> Quero que entregue nesse endereço</strong></label></div></div>' +
                '<div class="form-group row">' + '<label class="col-sm-2 control-label" for="usu_end_tipo">Tipo:</label>' +
                '<div class="col-sm-5">' + '<select id="usu_end_tipo" name="usu_end_tipo" class="form-control">' +
                '<option value="Casa">Casa</option>' + '<option value="Apartamento">Apartamento</option>' +
                '<option value="Comercial">Comercial</option><option value="Outros">Outros</option>' +
                '</select></div></div><div class="form-group row"><label class="col-sm-2 control-label" for="usu_end_contato">Contato:</label>' +
                '<div class="col-sm-5"><input id="usu_end_contato" name="usu_end_contato" type="text" placeholder="Contato" class="form-control input-md" >' +
                '</div></div><div class="form-group row"> <label class="col-sm-2 control-label" for="usu_end_endereco">Endereço:</label><div class="col-sm-6">' +
                '<input id="usu_end_endereco" name="usu_end_endereco" type="text" placeholder="Endereço" class="form-control input-md" >' +
                '</div><label class="col-sm-1 control-label" for="usu_end_numero">N.º:</label><div class="col-sm-2">' +
                '<input id="usu_end_numero" name="usu_end_numero" type="text" placeholder="Número" class="form-control input-md" > ' +
                '</div></div><div class="form-group row"><label class="col-sm-2 control-label" for="usu_end_complemento">Complemento:</label><div class="col-sm-6">' +
                '<input id="usu_end_complemento" name="usu_end_complemento" type="text" placeholder="Complemento" class="form-control input-md">' +
                '</div></div><div class="form-group row"><label class="col-sm-2 control-label" for="usu_end_bairro">Bairro</label><div class="col-sm-6">' +
                '<input id="usu_end_bairro" name="usu_end_bairro" type="text" placeholder="Bairro" class="form-control input-md" ></div></div>' +
                '<div class="form-group row"><label class="col-sm-2 control-label" for="usu_end_cep">CEP:</label><div class="col-sm-4">' +
                '<input id="usu_end_cep" name="usu_end_cep" type="text" placeholder="CEP" class="form-control input-md" maxlength="9" minlength="9">' +
                '</div></div><div class="form-group row"><label class="col-sm-2 control-label" for="usu_end_cidade">Cidade</label><div class="col-sm-6">' +
                '<input id="usu_end_cidade" name="usu_end_cidade" type="text" placeholder="Cidade" class="form-control input-md" >' +
                '</div><label class="col-sm-1 control-label" for="usu_end_uf">UF:</label><div class="col-sm-2"><input id="usu_end_uf" name="usu_end_uf" type="text" placeholder="UF" class="form-control input-md" maxlength="2" minlength="2">' +
                '</div></div></fieldset>',
                buttons: {Cancelar: false, Salvar: true},
                focus: 1,
                submit: function (e, v, m, f) {
                    var e = "";

                    if (v) {

                        if (f.usu_end_tipo === "") {
                            e += "Por favor selecione o Tipo <br>";
                        }
                        if (f.usu_end_contato === "") {
                            e += "Por favor digite o nome do Contato <br>";
                        }
                        if (f.usu_end_endereco === "") {
                            e += "Por favor digite o Endereço <br>";
                        }
                        if (f.usu_end_numero === "") {
                            e += "Por favor digite o Número <br>";
                        }
                        if (f.usu_end_bairro === "") {
                            e += "Por favor digite o Bairro <br>";
                        }
                        if (isNaN(f.usu_end_cep) || f.usu_end_cep === "") {
                            e += "Por favor digite o CEP (SOMENTE NÚMEROS) <br>";
                        }
                        if (f.usu_end_cidade === "") {
                            e += "Por favor digite o Cidade <br>";
                        }
                        if (f.usu_end_uf === "") {
                            e += "Por favor digite o Estado (ex: MT) <br>";
                        }
                        if (e === "") {
                            var usu_end_principal = $("#usu_end_principal").is(':checked');
                            var usu_end_tipo = $("#usu_end_tipo").val();
                            var usu_end_contato = $("#usu_end_contato").val();
                            var usu_end_endereco = $("#usu_end_endereco").val();
                            var usu_end_numero = $("#usu_end_numero").val();
                            var usu_end_bairro = $("#usu_end_bairro").val();
                            var usu_end_cep = $("#usu_end_cep").val();
                            var usu_end_cidade = $("#usu_end_cidade").val();
                            var usu_end_uf = $("#usu_end_uf").val();
                            var usu_end_complemento = $("#usu_end_complemento").val();
                            var usu_end_finalidade = 'EE';
                            var usu_end_pais = 'Brasil';
                            var usu_end_cxp = '';
                            var usu_end_obs = '';
                            var usu_end_status = '0';
                            var usu_end_1click = '0';

                            $.post('/commerce/checkout/addNovoEndereco/',
                                {
                                    usu_end_principal: usu_end_principal,
                                    usu_end_tipo: usu_end_tipo,
                                    usu_end_contato: usu_end_contato,
                                    usu_end_endereco: usu_end_endereco,
                                    usu_end_numero: usu_end_numero,
                                    usu_end_bairro: usu_end_bairro,
                                    usu_end_cep: usu_end_cep,
                                    usu_end_cidade: usu_end_cidade,
                                    usu_end_uf: usu_end_uf,
                                    usu_end_complemento: usu_end_complemento,
                                    usu_end_finalidade: usu_end_finalidade,
                                    usu_end_pais: usu_end_pais,
                                    usu_end_cxp: usu_end_cxp,
                                    usu_end_obs: usu_end_obs,
                                    usu_end_status: usu_end_status,
                                    usu_end_1click: usu_end_1click
                                },
                                function (data) {
                                    if (data.success) {
                                        $scope.getEnderecoPrincipal();
                                        $.prompt.goToState('state1');
                                        $('.jqiform')[0].reset();
                                        return false;
                                    }
                                    else {
                                        $.prompt.goToState('state2');
                                        return false;
                                    }
                                }, 'json');

                        }
                        else {
                            $('<div class="errorBlock" style="display: none;">' + e + '</div>').prependTo(m).show('slow');
                        }
                        return false;

                    }
                    else {
                        return true;
                    }

                }
            },
            state1: {
                html: '<p class="mensagem-success">Endereço cadastrado com sucesso!</p>',
                buttons: {Voltar: -1, Fechar: 0},
                focus: 1,
                submit: function (e, v, m, f) {
                    e.preventDefault();
                    if (v === 0) {
                        $.prompt.close();
                    }
                    else if (v == -1) {
                        $.prompt.goToState('state0');
                    }
                }
            },
            state2: {
                html: '<p class="mensagem-erro">Erro ao cadastrar Endereço, tente novamente!</p>',
                buttons: {Voltar: -1, Fechar: 0},
                focus: 1,
                submit: function (e, v, m, f) {
                    e.preventDefault();
                    if (v === 0) {
                        $.prompt.close();
                    }
                    else if (v == -1) {
                        $.prompt.goToState('state0');
                    }
                }
            }
        };

        $.prompt(statesdemo);
    };

    $scope.getEnderecoPrincipal = function () {
        $http.get('/commerce/checkout/getEnderecoPrincipal/').then(function (result) {

            if (result.data[0].success == 'error') {
                $.toast({
                    heading: 'Atenção',
                    text: 'Erro ao selecionar endereço.',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });
            }
            else {
                $scope.endereco = result.data;

                if (($localStorage.get('tipoFrete') == '41106') || ($localStorage.get('tipoFrete') == '40010')) {
                    $scope.reCalcFreteCorreios(result.data[0].usu_end_cep);
                } else {
                    getParcelasCartoes();
                }
                $scope.getEnderecos();
            }

        });
    };

    $scope.getEnderecos = function () {
        $http.get('/commerce/checkout/getEnderecos').then(function (result) {

            if (result.data[0].success == 'error') {
                $.toast({
                    heading: 'Atenção',
                    text: 'Erro ao selecionar endereço.',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });
            }
            else {
                $scope.enderecos = result.data;
            }

        });
    };

    $scope.selecionarEndereco = function (id) {

        $.prompt("<p>Você optou em mudar o endereço de entrega, <strong>o frete será recalculado</strong>, você confirma?<p>", {
            title: "Atenção",
            buttons: {"Sim, eu confirmo": true, "Não, deixe o mesmo": false},
            submit: function (e, v, m, f) {
                if (v) {
                    $http.get('/commerce/checkout/selecionarEndereco/' + id).then(function (result) {
                        if (result.data.success) {

                            $scope.endereco = result.data.endereco;

                            if (($localStorage.get('tipoFrete') == '41106') || ($localStorage.get('tipoFrete') == '40010')) {
                                $scope.reCalcFreteCorreios(result.data.endereco[0].usu_end_cep);
                            }
                            $scope.getEnderecos();
                            getParcelasCartoes();
                        }
                        else {
                            $.toast({
                                heading: 'Erro',
                                text: result.data.msg,
                                position: 'mid-center',
                                icon: 'error',
                                hideAfter: appConfig.timeToast,
                                stack: false
                            });
                        }
                    });
                }
            }
        });

    };

    $scope.reCalcFreteCorreios = function (cep) {
        var postalCodeID = cep;
        var subtotal = $("#subtotal").val();

        $.post('/commerce/cart/reCalculaFrete/',
            {postalCodeID: postalCodeID, subtotal: subtotal},
            function (data) {

                if (postalCodeID !== '') {

                    $.toast({
                        heading: 'Atenção',
                        text: 'Recalculando frete... Aguarde!',
                        position: 'mid-center',
                        icon: 'info',
                        hideAfter: appConfig.timeToast,
                        stack: false
                    });

                    switch (data.Erro) {
                        case '0':
                            $.toast().reset('all');

                            $scope.result.frete = numberFormat(parseFloat(data.Valor).toFixed(2));
                            $scope.result.total_pedido = numberFormat(parseFloat(data.Total).toFixed(2));
                            $scope.result.total_pedido_usd = parseFloat(data.Total).toFixed(2);

                            $scope.$apply();

                            getParcelasCartoes();

                            $('#parc_mastercard').html('&nbsp;');
                            $('#parc_elo').html('&nbsp;');
                            $('#parc_amex').html('&nbsp;');
                            $('#parc_visa').html('&nbsp;');

                            break;
                        case '010':
                            // O CEP de destino está temporariamente sem entrega domiciliar.
                            // A entrega será efetuada na agência indicada no Aviso de
                            // Chegada que será entregue no endereço do destinatário.
                            $.toast().reset('all');

                            $.toast({
                                heading: 'ATENÇÃO',
                                text: data.ErroMsg,
                                position: 'mid-center',
                                icon: 'info',
                                hideAfter: false,
                                stack: false
                            });

                            $scope.result.frete = numberFormat(parseFloat(data.Valor).toFixed(2));
                            $scope.result.total_pedido = numberFormat(parseFloat(data.Total).toFixed(2));
                            $scope.result.total_pedido_usd = parseFloat(data.Total).toFixed(2);

                            getParcelasCartoes();

                            $scope.$apply();

                            break;
                        default:
                            $.toast().reset('all');
                            $.toast({
                                heading: 'Erro',
                                text: data.ErroMsg,
                                position: 'mid-center',
                                icon: 'error',
                                hideAfter: appConfig.timeToast,
                                stack: false
                            });

                            break;
                    }

                } else {
                    $.toast().reset('all');
                    $.toast({
                        heading: 'Erro',
                        text: 'CEP Inv&aacute;lido',
                        position: 'mid-center',
                        icon: 'error',
                        hideAfter: appConfig.timeToast,
                        stack: false
                    });
                }
            }, 'json');
    };

    init();

}]);