app.controller('addCartController', ['$scope', '$http', '$window', 'appConfig', '$location', '$localStorage', function ($scope, $http, $window, appConfig, $location, $localStorage) {

    var $ = jQuery;

    $scope.addCart = function () {

        var prod_estoque_id = $('input:hidden[name=prod_estoque_id]').val();
        var prod_id = $('input:hidden[name=prod_id]').val();
        var prod_nome = $('input:hidden[name=prod_nome]').val();
        var prod_valor = $('input:hidden[name=prod_valor]').val();
        var prod_estoque_peso = $('input:hidden[name=prod_estoque_peso]').val();
        var prod_estoque_volume = $('input:hidden[name=prod_estoque_volume]').val();
        var qde = $('input:hidden[name=qde]').val();
        var acao = $('input:hidden[name=acao]').val();
        var listaId = $('input:hidden[name=listaId]').val();
        var foto = $('input:hidden[name=prod_ft_foto]').val();
        var url = $('input:hidden[name=listaSlug]').val();
        $localStorage.set('urlLista', $('input:hidden[name=listaSlug]').val());

        $.post('/commerce/cart/addCart',
            {
                prod_estoque_id: prod_estoque_id,
                prod_id: prod_id,
                prod_nome: prod_nome,
                prod_valor: prod_valor,
                prod_estoque_peso: prod_estoque_peso,
                prod_estoque_volume: prod_estoque_volume,
                qde: qde,
                acao: acao,
                listaId: listaId,
                foto: foto,
                url: url
            },
            function (data) {
                switch (data.success) {
                    case true:
                        $.prompt(data.msg, {
                            title: data.mod,
                            buttons: {"Ir para o Carrinho": true, "Continuar Comprando": false},
                            submit: function (e, v, m, f) {
                                if (v) {
                                    location.href = data.urlCart;
                                }
                                else {
                                    location.href = data.urlCommerce;
                                }
                            }
                        });

                        break;
                    case false:
                        switch (data.erro) {
                            //ERR1 - Não pode adicionar compra da lista de casamento na compra normal
                            case 'ERR1':
                                $.prompt(data.msg, {
                                    title: data.mod,
                                    buttons: {"Cancelar Compra Atual": true, "Ir para Loja": false},
                                    submit: function (e, v, m, f) {
                                        if (v) {
                                            $http.get('/commerce/cart/clearCart/').then(function (data) {
                                                if (data.success == true) {
                                                    location.href = data.urlCommerce;
                                                }
                                                else {
                                                    $window.console.log('Erro ao limpar carrinho - ERR1');
                                                    $window.console.log(data);
                                                }
                                            });
                                        }
                                        else {
                                            location.href = data.urlCommerce;
                                        }
                                    }
                                });
                                break;
                            //ERR1 - Não pode adicionar compra normal na lista de casamento
                            case 'ERR2':
                                $.prompt(data.msg, {
                                    title: data.mod,
                                    buttons: {"Cancelar Compra Atual": true, "Ir para Loja": false},
                                    submit: function (e, v, m, f) {
                                        if (v) {
                                            $http.get('/commerce/cart/clearCart/').then(function (data) {
                                                if (data.success == true) {
                                                    location.href = data.urlCommerce;
                                                }
                                                else {
                                                    $window.console.log('Erro ao limpar carrinho - ERR2');
                                                    $window.console.log(data);
                                                }
                                            });
                                        }
                                        else {
                                            location.href = data.urlCommerce;
                                        }
                                    }
                                });
                                break;
                            //ERR3 - Não pode adicionar itens de Lista de Casamento diferente no mesmo Carrinho
                            case 'ERR3':
                                $.prompt(data.msg, {
                                    title: data.mod,
                                    buttons: {"Cancelar Compra Atual": true, "Ir para Lista": false},
                                    submit: function (e, v, m, f) {
                                        if (v) {
                                            $http.get('/commerce/cart/clearCart/').then(function (data) {
                                                if (data.success == true) {
                                                    location.href = $location.baseURL + '/' + $localStorage.get('urlLista');
                                                }
                                                else {
                                                    $window.console.log('Erro ao limpar carrinho - ERR3');
                                                    $window.console.log(data);
                                                }
                                            });
                                        }
                                        else {
                                            location.href = $localStorage.get('urlBuscaLista');
                                        }
                                    }
                                });
                                break;
                        }
                    break;
                }
            },
            'json');
    };

    $scope.goToDescricao = function () {
        $('.produto-descricao').ScrollTo({
            duration: 1500,
            easing: 'linear'
        });
    }

    var init = function () {

    };

    init();

}]);