app.controller('loginController', ['$scope', '$http', '$window', 'appConfig', '$location', '$localStorage', function ($scope, $http, $window, appConfig, $location, $localStorage) {

    var $ = jQuery;
    var myToast;

    $scope.acessar = function () {

        var email = $('input[name=email]').val(); //$("#email").val();
        var password = $('input[name=password]').val(); //$("#password").val();
        var rota = $location.absUrl();
        ;

        if (email === '' && password === '') {

            $.toast({
                heading: 'Atenção',
                text: 'Os Campos E-Mail e Senha s&atilde;o Obrigat&oacute;rios!',
                position: 'mid-center',
                icon: 'info',
                hideAfter: appConfig.timeToast
            });

        }
        else {
            $.toast({
                heading: 'Atenção',
                text: 'Logando na loja... Aguarde!',
                position: 'mid-center',
                icon: 'info',
                hideAfter: appConfig.timeToast
            });

            $.post('/auth/logar',
                {email: email, password: password, rota: rota},
                function (data) {
                    switch (data.success) {
                        case true:

                            $.toast().reset('all');

                            $.toast({
                                heading: 'Sucesso',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'success',
                                hideAfter: appConfig.timeToast - 3000,
                                afterHidden: function () {
                                    location.href = $localStorage.get('url');
                                }
                            });

                            break;

                        case false:
                            $.toast().reset('all');

                            $.toast({
                                heading: 'Erro',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'error',
                                hideAfter: appConfig.timeToast,
                                close: function () {
                                    if (data.url !== '') {
                                        location.href = data.url;
                                    }
                                }
                            });
                            break;

                    }

                }, 'json');
        }
    };

    $scope.recSenha = function () {

        var email = $('input[name=email]').val();

        if (email === '') {

            $.toast({
                heading: 'Atenção',
                text: 'O Campo E-Mail &eacute; Obrigat&oacute;rio!',
                position: 'mid-center',
                icon: 'warning',
                stack: false
            });
        }
        else {
            $.toast({
                heading: 'Atenção',
                text: 'Recuperando Senha... Aguarde!',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: 20000,
                stack: false
            });

            $.post('/user/recsenha/',
                {email: email},
                function (data) {
                    switch (data.success) {
                        case true:

                            $.toast().reset('all');

                            $.toast({
                                heading: 'Sucesso',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'success',
                                stack: false
                            });


                            break;
                        case false:

                            $.toast().reset('all');

                            $.toast({
                                heading: 'Atenção',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'warning',
                                stack: false
                            });

                            break;
                        case 'emailnaoexiste':

                            $.toast().reset('all');

                            $.toast({
                                heading: 'Atenção',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'warning',
                                stack: false
                            });
                            break;
                    }

                }, 'json');
        }
    };

}]);