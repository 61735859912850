app.controller('checkoutOrcController', ['$scope', '$http', '$window', 'appConfig', '$localStorage', function ($scope, $http, $window, appConfig, $localStorage) {

    var $ = jQuery;
    $scope.data = {active: false};
    $scope.active = true;
    $scope.result = {};

    $scope.finalizarOrcamento = function () {
        $.prompt("Deseja enviar esse Orçamento agora?", {
            title: "Atenção",
            buttons: {"SIM": true, "NÃO": false},
            submit: function (e, v, m, f) {
                if (v) {
                    var v1 = '';
                    var v2 = '';
                    $.toast({
                        heading: 'Enviando',
                        text: 'Aguarde... Enviando Orçamento',
                        position: 'mid-center',
                        icon: 'info',
                        hideAfter: 5000,
                        stack: false
                    });
                    $.post('/orcamento/checkout/send/',
                        {v1: v1, v2: v2},
                        function (data) {
                            if (data.success) {
                                $.toast().reset('all');
                                $.toast({
                                    heading: 'Enviando',
                                    text: 'Orçamento enviado com sucesso!',
                                    position: 'mid-center',
                                    icon: 'success',
                                    hideAfter: 5000,
                                    beforeHide: function () {
                                        location.href = '/orcamento/sucesso';
                                    },
                                    stack: false
                                });
                            } else {
                                $.toast().reset('all');
                                $.toast({
                                    heading: 'Atenção',
                                    text: 'Orçamento não enviado, tente novamente.',
                                    position: 'mid-center',
                                    icon: 'error',
                                    hideAfter: 5000,
                                    beforeHide: function () {
                                        //location.href = '/user/dashboard/success';
                                    },
                                    stack: false
                                });
                            }
                        }, 'json');
                }
            }
        });
    };

    $scope.goToContinuarComprando = function () {
        location.href = $localStorage.get('url-cont-comprando');
    };

    var init = function () {

        $http.get('/orcamento/cart/getCart').then(function (result) {
            if (result.data[0].success == false) {
                $.toast({
                    heading: 'Informação',
                    text: 'Checkout está vazio',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 3000,
                    beforeHide: function () {
                        location.href = "/";
                    },
                    stack: false
                });
            } else {
                $scope.itens = result.data;
            }
        });
    };

    init();

}]);