app.controller('orcController', ['$scope', '$http', '$window', 'appConfig', '$localStorage', '$location', function ($scope, $http, $window, appConfig, $localStorage, $location) {

    var $ = jQuery;
    $scope.showGrid = false;
    $scope.result = {

    };

    $scope.excluir = function (item) {
        $.prompt('Tem certeza que deseja retirar o produto "' + item.prod_nome + '" do orçamento?', {
            title: 'Atenção',
            buttons: {"SIM": true, "NÃO": false},
            submit: function (e, v, m, f) {
                if (v) {
                    $http.get('/orcamento/cart/removeItemCart/' + item.prod_estoque_id + '/' + item.prod_cor_id+ '/' + item.tamanho).then(function (result) {
                        if (result.data[0].success) {

                            $scope.itens = result.data;

                            reset();
                        } else {
                            var index = $scope.itens.indexOf(item);
                            $scope.itens.splice(index, 1);
                            $.toast({
                                heading: 'Informação',
                                text: 'Não há itens no orçamento.',
                                position: 'mid-center',
                                icon: 'info',
                                hideAfter: 2000,
                                stack: false,
                                beforeHide: function () {
                                    $scope.goToContinuarComprando();
                                }
                            });
                        }
                    });
                } else {

                }
            }
        });
    };

    $scope.atualizar = function (item) {
        $http.get('/orcamento/cart/updCart/' + item.prod_estoque_id + '/' + item.prod_cor_id + '/' + item.tamanho + '/' + item.qde).then(function (result) {
            if (result.data[0].success) {

                $scope.itens = result.data;

                reset();

            } else {
                var index = $scope.itens.indexOf(item);
                $scope.itens.splice(index, 1);
                $.toast({
                    heading: 'Informação',
                    text: 'Não há itens no seu Carrinho de Compras.',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 2000,
                    stack: false,
                    beforeHide: function () {
                        $scope.goToContinuarComprando();
                    }
                });
            }
        });
    };

    $scope.goToContinuarComprando = function () {
        location.href = $localStorage.get('url-cont-comprando');
    };

    $scope.goToCheckout = function () {
        $localStorage.set('checkout', true);
        $localStorage.set('url', '/orcamento/checkout');
        location.href = "/orcamento/checkout";
    };

    var init = function () {

        $http.get('/orcamento/cart/getCart').then(function (result) {
            if (result.data[0].success == false) {
                $.toast({
                    heading: 'Informação',
                    text: 'Não há itens no seu orçamento',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 3000,
                    stack: false,
                    beforeHide: function () {
                        $scope.goToContinuarComprando();
                    }
                });
            }
            else {
                $scope.itens = result.data;
            }

        });
    };

    init();

}]);