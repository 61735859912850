app.controller('cartController', ['$scope', '$http', '$window', 'appConfig', '$localStorage', function ($scope, $http, $window, appConfig, $localStorage) {

    var $ = jQuery;
    var shownCEP = $("#showCep");
    var shownMSG = $("#showMSG");
    var span = $("#showMSG > span");
    $scope.showGrid = false;
    $scope.result = {
        total_parcial: 0.00,
        total_parcial_usd: 0.00,
        frete: '0,00',
        total_pedido: 0.00
    };

    $scope.excluir = function (item) {
        $.prompt('Tem certeza que deseja retirar o produto "' + item.prod_nome + '" do carrinho?', {
            title: 'Atenção',
            buttons: {"SIM": true, "NÃO": false},
            submit: function (e, v, m, f) {
                if (v) {
                    $http.get('/commerce/cart/removeItemCart/' + item.prod_estoque_id).then(function (result) {
                        if (result.data[0].success) {

                            $scope.itens = result.data;
                            $scope.result.frete = '0,00';
                            $scope.result.total_parcial = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));
                            $scope.result.total_parcial_usd = parseFloat(result.data[0].total_parcial).toFixed(2);
                            $scope.result.total_pedido = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));

                            reset();
                        } else {
                            var index = $scope.itens.indexOf(item);
                            $scope.itens.splice(index, 1);
                            $.toast({
                                heading: 'Informação',
                                text: 'Não há itens no seu Carrinho de Compras.',
                                position: 'mid-center',
                                icon: 'info',
                                hideAfter: 2000,
                                stack: false,
                                beforeHide: function () {
                                    $scope.goToContinuarComprando();
                                }
                            });
                        }
                    });
                } else {

                }
            }
        });
    };

    $scope.atualizar = function (item) {
        $http.get('/commerce/cart/updCart/' + item.prod_estoque_id + '/' + item.qde).then(function (result) {
            if (result.data[0].success) {
                $scope.itens = result.data;
                $scope.result.frete = '0,00';
                $scope.result.total_parcial = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));
                $scope.result.total_parcial_usd = parseFloat(result.data[0].total_parcial).toFixed(2);
                $scope.result.total_pedido = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));

                reset();

            } else {
                var index = $scope.itens.indexOf(item);
                $scope.itens.splice(index, 1);
                $.toast({
                    heading: 'Informação',
                    text: 'Não há itens no seu Carrinho de Compras.',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 2000,
                    stack: false,
                    beforeHide: function () {
                        $scope.goToContinuarComprando();
                    }
                });
            }
        });
    };

    var reset = function () {
        $("#escolhafrete option:first").attr('selected', 'selected');
        $scope.tipoFrete = $scope.tipoFretes[0];
        limparCEP();
        hideCEP();
        hideMSG();
    }

    var limparCEP = function () {
        $("#postalCodeID1").val('');
        $("#postalCodeID2").val('');
    };

    var hideCEP = function () {
        shownCEP.removeClass('showCep').addClass('hideCep');
    };
    var showCEP = function () {
        shownCEP.removeClass('hideCep').addClass('showCep');
    };

    var hideMSG = function () {
        shownMSG.removeClass('showMSG').addClass('hideMSG');
    };
    var showMSG = function () {
        shownMSG.removeClass('hideMSG').addClass('showMSG');
    };

    $scope.goToContinuarComprando = function () {
        location.href = "/commerce";
    };

    $scope.onChangeSelect = function () {

        switch ($scope.tipoFrete.tipo) {
            case '0':

                $localStorage.set('tipoFrete', 0);
                hideCEP();
                hideMSG();

                break;
            case '1':
                $localStorage.set('tipoFrete', 1);

                hideCEP();
                showMSG();

                span.html('Somente para Cuiabá e Várzea Grande ( MT )');

                var valorCompras = $('#subtotal').val();
                var tipofrete = $("option:selected").val();
                var lista = 0;
                var total;

                $.post('/commerce/cart/calculaFrete/',
                    {subtotal: valorCompras, tipofrete: tipofrete, lista: lista},
                    function (data) {
                        total = parseFloat(valorCompras) + parseFloat(data.frete);
                        $scope.result.frete = numberFormat(data.frete);
                        $scope.result.total_pedido = numberFormat(total.toFixed(2));
                        $scope.$apply();
                    }, 'json');

                break;
            case '41106':
                $localStorage.set('tipoFrete', '41106');
                showCEP();
                hideMSG();

                $scope.result.frete = '0,00';
                $scope.result.total_pedido = $scope.result.total_parcial;

                break;

            case '40010':
                $localStorage.set('tipoFrete', '40010');
                showCEP();
                hideMSG();

                $scope.result.frete = '0,00';
                $scope.result.total_pedido = $scope.result.total_parcial;

                break;
        }

    };

    // var getSubtotal = function () {
    //     $http.get('/commerce/cart/getSubTotal').success(function (data) {
    //         var total_parcial = parseFloat(data).toFixed(2);
    //         if (isNaN(total_parcial)) {
    //             $scope.result.total_parcial_usa = data;
    //             $scope.result.total_parcial = numberFormat(parseFloat(0.00).toFixed(2));
    //         }
    //         else {
    //             $scope.result.total_parcial_usd = data;
    //             $scope.result.total_parcial = numberFormat(parseFloat(data).toFixed(2));
    //             $scope.result.total_pedido = numberFormat(parseFloat(data).toFixed(2));
    //         }
    //         $("#escolhafrete option:first").attr('selected', 'selected');
    //     });
    // };

    $scope.calcFreteCorreios = function () {

        var postalCodeID1 = $("#postalCodeID1").val();
        var postalCodeID2 = $("#postalCodeID2").val();
        var subtotal = $("#subtotal").val();
        var tipofrete = $("option:selected").val();
        var lista = 0;

        if ($scope.tipoFrete.tipo != 0) {

            if (postalCodeID1 != '' && postalCodeID2 != '') {

                $.toast({
                    heading: 'Informação',
                    text: 'Pesquisando... Aguarde!',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });

                $.post('/commerce/cart/calculaFrete/',
                    {
                        postalCodeID1: postalCodeID1,
                        postalCodeID2: postalCodeID2,
                        subtotal: subtotal,
                        tipofrete: tipofrete,
                        lista: lista
                    },
                    function (data) {

                        switch (data.Erro) {
                            case '0':
                                $.toast().reset('all');

                                $scope.result.frete = numberFormat(parseFloat(data.Valor).toFixed(2));
                                $scope.result.total_pedido = data.Total;

                                $scope.$apply();

                                break;
                            case '010':
                                // O CEP de destino está temporariamente sem entrega domiciliar.
                                // A entrega será efetuada na agência indicada no Aviso de
                                // Chegada que será entregue no endereço do destinatário.
                                $.toast().reset('all');

                                $.toast({
                                    heading: 'ATENÇÃO',
                                    text: data.ErroMsg,
                                    position: 'mid-center',
                                    icon: 'info',
                                    hideAfter: false,
                                    stack: false
                                });

                                $scope.result.frete = numberFormat(parseFloat(data.Valor).toFixed(2));
                                $scope.result.total_pedido = numberFormat(parseFloat(data.Total).toFixed(2));

                                $scope.$apply();

                                break;
                            default:
                                $.toast().reset('all');
                                $.toast({
                                    heading: 'Erro',
                                    text: data.ErroMsg,
                                    position: 'mid-center',
                                    icon: 'error',
                                    hideAfter: appConfig.timeToast,
                                    stack: false
                                });

                                break;
                        }
                    }, 'json');

            } else {
                $.toast({
                    heading: 'Atenção',
                    text: 'Por favor, informe o CEP corretamente',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });
            }
        } else {
            $.toast({
                heading: 'Atenção',
                text: 'Escolha o tipo de frete antes consultar o valor.',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: 3000,
                stack: false
            });
        }
    };

    $scope.goToCheckout = function () {
        if ($scope.tipoFrete.tipo == 0) {
            $.toast({
                heading: 'Atenção',
                text: 'Escolha o tipo de frete antes de fechar o pedido.',
                position: 'mid-center',
                icon: 'warning',
                hideAfter: 3000,
                stack: false
            });
        }
        else {
            if ($scope.result.subtotal_usd < 1.00) {
                $.toast({
                    heading: 'Atenção',
                    text: 'O pedido mínimo para compra pelo site é de R$ 1,00.',
                    position: 'mid-center',
                    icon: 'warning',
                    hideAfter: appConfig.timeToast,
                    stack: false
                });
            }
            else {
                $localStorage.set('url', '/commerce/checkout');
                location.href = "/commerce/checkout";
            }
        }
    };

    var init = function () {
        $http.get('/commerce/cart/getCart').then(function (result) {
            if (result.data[0].success == false) {
                $.toast({
                    heading: 'Informação',
                    text: 'Não há itens no seu Carrinho de Compras',
                    position: 'mid-center',
                    icon: 'info',
                    hideAfter: 3000,
                    stack: false,
                    beforeHide: function () {
                        $scope.goToContinuarComprando();
                    }
                });
            }
            else {
                $scope.itens = result.data;
                $scope.result.frete = '0,00';
                $scope.result.total_parcial = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));
                $scope.result.total_parcial_usd = parseFloat(result.data[0].total_parcial).toFixed(2);
                $scope.result.total_pedido = numberFormat(parseFloat(result.data[0].total_parcial).toFixed(2));
            }

        });
        $scope.tipoFrete = $scope.tipoFretes[0];
    };


    $scope.tipoFretes = [
        {nome: 'Selecione o Tipo', tipo: '0'},
        {nome: 'Motoboy', tipo: '1'},
        {nome: 'Correios(PAC)', tipo: '41106'},
        {nome: 'Correios(SEDEX)', tipo: '40010'}
    ];

    var numberFormat = function (n) {
        var parts = n.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
    };

    init();

}]);