// JavaScript Document - Controller Carrinho de Compras - AngularJS
app.controller('trabalheController', ['$scope', '$http', 'appConfig', function trabalheController($scope, $http, appConfig) {

    var $ = jQuery;

    $scope.cur_tipo_hab_A = '';
    $scope.cur_tipo_hab_B = '';
    $scope.cur_tipo_hab_C = '';
    $scope.cur_tipo_hab_D = '';

    $scope.Enviar = function () {
        validate('form-trabalhe');

        if (validateState) {
            $.toast({
                heading: 'Informação',
                text: 'Enviando Curriculum. Aguarde...',
                position: 'mid-center',
                icon: 'info',
                hideAfter: appConfig.timeToast,
                stack: false
            });

            var cur_nome = $("#cur_nome").val();
            var cur_email = $("#cur_email").val();
            var cur_prefixo = $("#cur_prefixo").val();
            var cur_telefone = $("#cur_telefone").val();
            var cur_celular = $("#cur_celular").val();
            var cur_endereco = $("#cur_endereco").val();
            var cur_numero = $("#cur_numero").val();
            var cur_bairro = $("#cur_bairro").val();
            var cur_cep = $("#cur_cep").val();
            var cur_uf = $('#cur_uf option:selected').text();
            var cur_cidade = $("#cur_cidade").val();
            var cur_datanasc = $("#cur_datanasc").val();
            var cur_estado_civil = $('#cur_estado_civil option:selected').text();
            var cur_rg = $("#cur_rg").val();
            var cur_cpf = $("#cur_cpf").val();
            var cur_sexo = $('#cur_sexo option:selected').text();
            var cur_nomepai = $("#cur_nomepai").val();
            var cur_nomemae = $("#cur_nomemae").val();
            var cur_habilitacao = $("#form-trabalhe input[name='cur_habilitacao']:checked").val();
            var cur_tipo_hab = $scope.cur_tipo_hab_A + $scope.cur_tipo_hab_B + $scope.cur_tipo_hab_C + $scope.cur_tipo_hab_D;
            var cur_cart_prof = $("#form-trabalhe input[name='cur_cart_prof']:checked").val();
            var cur_tipo_cart_prof = $('#cur_tipo_cart_prof option:selected').text();
            var cur_form_formacao = $('#cur_form_formacao option:selected').text();
            var cur_form_curso = $("#cur_form_curso").val();
            var cur_form_conclusao = $("#cur_form_conclusao").val();

            var cur_exp_empresa1 = $("#cur_exp_empresa1").val();
            var cur_exp_segmento1 = $("#cur_exp_segmento1").val();
            var cur_exp_cargo1 = $("#cur_exp_cargo1").val();
            var cur_exp_inicio1 = $("#cur_exp_inicio1").val();
            var cur_exp_saida1 = $("#cur_exp_saida1").val();
            var cur_exp_atribuicao1 = $("#cur_exp_atribuicao1").val();

            var cur_exp_empresa2 = $("#cur_exp_empresa2").val();
            var cur_exp_segmento2 = $("#cur_exp_segmento2").val();
            var cur_exp_cargo2 = $("#cur_exp_cargo2").val();
            var cur_exp_inicio2 = $("#cur_exp_inicio2").val();
            var cur_exp_saida2 = $("#cur_exp_saida2").val();
            var cur_exp_atribuicao2 = $("#cur_exp_atribuicao2").val();

            var cur_exp_empresa3 = $("#cur_exp_empresa3").val();
            var cur_exp_segmento3 = $("#cur_exp_segmento3").val();
            var cur_exp_cargo3 = $("#cur_exp_cargo3").val();
            var cur_exp_inicio3 = $("#cur_exp_inicio3").val();
            var cur_exp_saida3 = $("#cur_exp_saida3").val();
            var cur_exp_atribuicao3 = $("#cur_exp_atribuicao3").val();

            var cur_cargo_pretendido = $("#cur_cargo_pretendido").val();
            var cur_faixa_salarial = $("#cur_faixa_salarial").val();
            var cur_sub_3meses_exp = $("#cur_sub_3meses_exp").val();

            $.post('/trabalhe/sendmail', {
                    cur_nome: cur_nome,
                    cur_email: cur_email,
                    cur_prefixo: cur_prefixo,
                    cur_telefone: cur_telefone,
                    cur_celular: cur_celular,
                    cur_endereco: cur_endereco,
                    cur_numero: cur_numero,
                    cur_bairro: cur_bairro,
                    cur_cep: cur_cep,
                    cur_uf: cur_uf,
                    cur_cidade: cur_cidade,
                    cur_datanasc: cur_datanasc,
                    cur_estado_civil: cur_estado_civil,
                    cur_rg: cur_rg,
                    cur_cpf: cur_cpf,
                    cur_sexo: cur_sexo,
                    cur_nomepai: cur_nomepai,
                    cur_nomemae: cur_nomemae,
                    cur_habilitacao: cur_habilitacao,
                    cur_tipo_hab: cur_tipo_hab,
                    cur_cart_prof: cur_cart_prof,
                    cur_tipo_cart_prof: cur_tipo_cart_prof,
                    cur_form_formacao: cur_form_formacao,
                    cur_form_curso: cur_form_curso,
                    cur_form_conclusao: cur_form_conclusao,
                    cur_exp_empresa1: cur_exp_empresa1,
                    cur_exp_segmento1: cur_exp_segmento1,
                    cur_exp_cargo1: cur_exp_cargo1,
                    cur_exp_inicio1: cur_exp_inicio1,
                    cur_exp_saida1: cur_exp_saida1,
                    cur_exp_atribuicao1: cur_exp_atribuicao1,
                    cur_exp_empresa2: cur_exp_empresa2,
                    cur_exp_segmento2: cur_exp_segmento2,
                    cur_exp_cargo2: cur_exp_cargo2,
                    cur_exp_inicio2: cur_exp_inicio2,
                    cur_exp_saida2: cur_exp_saida2,
                    cur_exp_atribuicao2: cur_exp_atribuicao2,
                    cur_exp_empresa3: cur_exp_empresa3,
                    cur_exp_segmento3: cur_exp_segmento3,
                    cur_exp_cargo3: cur_exp_cargo3,
                    cur_exp_inicio3: cur_exp_inicio3,
                    cur_exp_saida3: cur_exp_saida3,
                    cur_exp_atribuicao3: cur_exp_atribuicao3,
                    cur_cargo_pretendido: cur_cargo_pretendido,
                    cur_faixa_salarial: cur_faixa_salarial,
                    cur_sub_3meses_exp: cur_sub_3meses_exp
                },
                function (data) {
                    switch (data.success) {
                        case 'true':

                            $.toast.reset();

                            $.toast({
                                heading: 'Sucesso',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'success',
                                hideAfter: appConfig.timeToast,
                                stack: false
                            });

                            break;
                        case 'false':
                            $.toast.reset();

                            $.toast({
                                heading: 'Atenção',
                                text: data.erroMsg,
                                position: 'mid-center',
                                icon: 'warning',
                                hideAfter: appConfig.timeToast,
                                stack: false
                            });

                            break;
                    }

                }, 'json');
        }//end if validateState

    }

    $scope.reset = function () {
        $("#cur_nome").val('');
        $("#cur_email").val('');
        $("#cur_prefixo").val('');
        $("#cur_telefone").val('');
        $("#cur_celular").val('');
        $("#cur_endereco").val('');
        $("#cur_numero").val('');
        $("#cur_bairro").val('');
        $("#cur_cep").val('');
        $("#cur_uf").val('');
        $("#cur_cidade").val('');
        $("#cur_datanasc").val('');
        $('#cur_estado_civil').val('');
        $("#cur_rg").val('');
        $("#cur_cpf").val('');
        $('#cur_sexo').val('');
        $("#cur_nomepai").val('');
        $("#cur_nomemae").val('');
        $("#cur_habilitacao").attr("checked", false);
        $scope.cur_tipo_hab_A, $scope.cur_tipo_hab_B, $scope.cur_tipo_hab_C, $scope.cur_tipo_hab_D = '';
        $("#cur_cart_prof").attr("checked", false);
        $('#cur_tipo_cart_prof').val('');
        $('#cur_form_formacao').val('');
        $("#cur_form_curso").val('');
        $("#cur_form_conclusao").val('');
        $("#cur_exp_empresa1").val('');
        $("#cur_exp_segmento1").val('');
        $("#cur_exp_cargo1").val('');
        $("#cur_exp_inicio1").val('');
        $("#cur_exp_saida1").val('');
        $("#cur_exp_atribuicao1").val('');
        $("#cur_exp_empresa2").val('');
        $("#cur_exp_segmento2").val('');
        $("#cur_exp_cargo2").val('');
        $("#cur_exp_inicio2").val('');
        $("#cur_exp_saida2").val('');
        $("#cur_exp_atribuicao2").val('');
        $("#cur_exp_empresa3").val('');
        $("#cur_exp_segmento3").val('');
        $("#cur_exp_cargo3").val('');
        $("#cur_exp_inicio3").val('');
        $("#cur_exp_saida3").val('');
        $("#cur_exp_atribuicao3").val('');
        $("#cur_cargo_pretendido").val('');
        $("#cur_faixa_salarial").val('');
        $("#cur_sub_3meses_exp").attr("checked", false);
    }

    $scope.estados = [
        {name: 'Acre', value: '1'},
        {name: 'Alagoas', value: '2'},
        {name: 'Amazonas', value: '3'},
        {name: 'Amapá', value: '4'},
        {name: 'Bahia', value: '5'},
        {name: 'Ceará', value: '6'},
        {name: 'Distrito Federal', value: '7'},
        {name: 'Espírito Santo', value: '8'},
        {name: 'Goiás', value: '9'},
        {name: 'Maranhão', value: '10'},
        {name: 'Minas Gerais', value: '11'},
        {name: 'Mato Grosso do Sul', value: '12'},
        {name: 'Mato Grosso', value: '13'},
        {name: 'Pará', value: '14'},
        {name: 'Paraíba', value: '15'},
        {name: 'Pernambuco', value: '16'},
        {name: 'Piauí', value: '17'},
        {name: 'Paraná', value: '18'},
        {name: 'Rio de Janeiro', value: '19'},
        {name: 'Rio Grande do Norte', value: '20'},
        {name: 'Rondonia', value: '21'},
        {name: 'Roraima', value: '22'},
        {name: 'Rio Grande do Sul', value: '23'},
        {name: 'Santa Catarina', value: '24'},
        {name: 'Sergipe', value: '25'},
        {name: 'São Paulo', value: '26'},
        {name: 'Tocantins', value: '27'}
    ];

    $scope.estado = $scope.estados[12];

    var init = function () {
        $scope.reset();
        $scope.cidades();
    };

    /* PEGAR AS CIDADES -----------------------------------------------------------------------------------*/

    $scope.cidades = function () {

        $('.carregando').show();
        $http.get('estado/' + $scope.estado.value).then(function (data) {
            $scope.listacidades = data.data;
            if ($scope.estado.value == 13) {
                $scope.cidade = $scope.listacidades[74];
            }
            else {
                $scope.cidade = $scope.listacidades[0];
            }
            $('.carregando').hide();
        });
    };

    init();

}]);
