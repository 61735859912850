app.controller('newsletterController', function newsletterController($scope, $http, $window) {

    var $ = jQuery;
    var base_url = window.location.origin;

    $scope.Enviar = function () {

        validate('form-newsletter');

        if (validateState) {

            toastr.info('Cadastrando. Aguarde...', ' ' , {closeButton: true, positionClass: "toast-top-full-width"});

            var nome = $("#nome").val();
            var email = $("#email").val();

            $.post(base_url+'/contato/sendnews',
                {
                    nome: nome,
                    email: email,
                    _token: token
                },
                function (data) {
                    switch (data.success) {

                        case 'true':
                            toastr.clear();

                            toastr.success(data.erroMsg, 'Sucesso' , {
                                closeButton: true,
                                positionClass: "toast-top-full-width",
                                onHidden: function() {
                                    reset();
                                }
                            });

                            break;
                        case 'false':
                            toastr.clear();
                            toastr.warning(data.erroMsg);

                        case 'false_captcha':
                            toastr.clear();
                            toastr.warning(data.erroMsg);

                            break;
                    }

                }, 'json');
        }//end if validateState
    }

    var reset = function () {
        $("#nome").val('');
        $("#email").val('');
    }

    var init = function () {
        reset();
    };

    /* PEGAR AS CIDADES -----------------------------------------------------------------------------------*/

    init();

});